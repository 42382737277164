<template>
  <div class="ipxx-login-header">
    <h1>
      {{ titleText }}
    </h1>
    <div v-show="isLoading">
      <fa-icon
        icon="circle-notch"
        size="6x"
        class="fa-spin"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginHeader',
  computed: {
    error() {
      return this.$store.getters.getError;
    },
    /**
     * Returns the current external authentication provider label.
     */
    externalAuthProviderLabel() {
      const { authProviderId } = this.$route.params;
      if (authProviderId === this.$config.providers.azure) {
        return this.$t('providers.azure');
      }
      return this.$t('providers.default');
    },
    titleText() {
      let title = this.$t('titles.execute_login');
      if (this.$store.getters.isLoggedIn) {
        title = this.$t('titles.login_success');
      } else if (this.error) {
        title = this.errorTitle;
      }
      return title;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    errorTitle() {
      let translatedErrorTitle = '';
      switch (this.error) {
        case this.$config.errors.account_authorize_assigned_error:
          translatedErrorTitle = this.$t('titles.connect_account',
            { provider: this.externalAuthProviderLabel });
          break;
        case this.$config.errors.account_authorize_email_not_verified:
          translatedErrorTitle = this.$t('titles.verify_email');
          break;
        default:
          translatedErrorTitle = this.$t('titles.login_error');
      }
      return translatedErrorTitle;
    },
  },
};
</script>
