<template>
  <b-alert
    show
    variant="warning"
  >
    Something's gone wrong.
    This is the dummy logout component. Did you register the component correctly?
  </b-alert>
</template>

<script>
export default {
  name: 'DummyLogout',
};
</script>
