<template>
  <div class="ipxx-azure-logout px-5" />
</template>

<script>
export default {
  name: 'AzureLogout',
  props: {
    query: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$store.dispatch('finishLogout');
  },
};
</script>
