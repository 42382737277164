<template>
  <div class="ipxx-login-view mx-4 pt-5">
    <ipxx-login-header />
    <b-row
      v-if="isMyPmBridgeError"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col mt-3">
        <b-alert
          show
          variant="warning"
          v-html="$t('errors.unknown_qt_function')"
        />
      </div>
    </b-row>
    <div v-if="showVerifyEmail || emailToVerify">
      <ipxx-verify-email-form
        :user-email="emailToVerify"
      />
    </div>
    <div v-else-if="showConnectAccount">
      <ipxx-connect-account />
    </div>
    <component
      :is="currentLoginComponent"
    />
  </div>
</template>

<script>
import IpxxAzureLogin from '@/components/AzureLogin';
import IpxxConnectAccount from '@/components/ConnectAccount';
import IpxxDummyLogin from '@/components/DummyLogin';
import IpxxLoginHeader from '@/components/LoginHeader';
import IpxxVerifyEmailForm from '@/components/VerifyEmailForm';

export default {
  name: 'LoginView',
  components: {
    IpxxAzureLogin,
    IpxxConnectAccount,
    IpxxDummyLogin,
    IpxxLoginHeader,
    IpxxVerifyEmailForm,
  },
  computed: {
    currentLoginComponent() {
      const { authProviderId } = this.$route.params;
      let componentName;
      switch (authProviderId) {
        case this.$config.providers.azure:
          componentName = 'IpxxAzureLogin';
          break;
        default:
          componentName = 'IpxxDummyLogin';
      }
      return componentName;
    },
    emailToVerify() {
      return this.$store.getters.getRegistrationEmail;
    },
    isMyPmBridgeError() {
      const error = this.$store.getters.getError;
      return error === this.$config.bridgeErrors.unknown_function;
    },
    showConnectAccount() {
      const error = this.$store.getters.getError;
      return error === this.$config.errors.account_authorize_assigned_error;
    },
    showVerifyEmail() {
      const error = this.$store.getters.getError;
      return error === this.$config.errors.account_authorize_email_not_verified;
    },
  },
};
</script>
