<template>
  <b-alert
    show
    variant="warning"
  >
    Something's gone wrong.
    This is the dummy login component. Did you register the component correctly?
  </b-alert>
</template>

<script>
export default {
  name: 'DummyLogin',
};
</script>
