<template>
  <div class="ipxx-connect-account">
    <transition
      name="fade-slide"
      mode="out-in"
    >
      <ipxx-assign-account-form
        v-if="showAssignAccountForm"
        key="assignForm"
        :email-address="emailAddress"
        :is-email-sent="isEmailSent"
        :requested-email-for-user="requestedEmailForUsername"
        @showPasswordForgot="showPasswordForgot"
        @showRegister="showRegister"
      />
      <ipxx-register-form
        v-else-if="showRegisterForm"
        key="registerForm"
        @cancel="setDefault"
      />
      <ipxx-password-forgot
        v-else-if="showPasswordForgotForm"
        key="passwordForm"
        @cancel="setDefault"
        @emailSent="onEmailSent"
      />
    </transition>
  </div>
</template>

<script>
import IpxxAssignAccountForm from './AssignAccountForm';
import IpxxPasswordForgot from './PasswordForgot';
import IpxxRegisterForm from './RegisterForm';

export default {
  name: 'ConnectAccount',
  components: {
    IpxxAssignAccountForm,
    IpxxPasswordForgot,
    IpxxRegisterForm,
  },
  data() {
    return {
      emailAddress: null,
      isEmailSent: false,
      requestedEmailForUsername: null,
      showAssignAccountForm: true,
      showPasswordForgotForm: false,
      showRegisterForm: false,
    };
  },
  methods: {
    /**
     * Shows the assign account form with a success info.
     */
    onEmailSent(requestInfo) {
      this.isEmailSent = true;
      this.emailAddress = requestInfo.email;
      this.requestedEmailForUsername = requestInfo.username;
      this.setDefault();
    },
    /**
     * Sets the component values to its default.
     */
    setDefault() {
      this.showAssignAccountForm = true;
      this.showRegisterForm = false;
      this.showPasswordForgotForm = false;
    },
    /**
     * Only shows the password forgot form.
     */
    showPasswordForgot() {
      this.showAssignAccountForm = false;
      this.showPasswordForgotForm = true;
      this.showRegisterForm = false;
    },

    /**
     * Only shows the register form.
     */
    showRegister() {
      if (process.env.VUE_APP_REGISTRATION_ENABLED) {
        this.showAssignAccountForm = false;
        this.showPasswordForgotForm = false;
        this.showRegisterForm = true;
      }
    },
  },
};
</script>
