<template>
  <div class="ipxx-logout-header">
    <h1>
      {{ titleText }}
    </h1>
    <div v-show="isLoading">
      <fa-icon
        icon="circle-notch"
        size="6x"
        class="fa-spin"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoutHeader',
  computed: {
    titleText() {
      let title = this.$t('titles.execute_logout');
      if (this.$store.getters.isLoggedIn) {
        title = this.$t('titles.logout_success');
      } else if (this.$store.getters.getError) {
        title = this.$t('titles.logout_error');
      }
      return title;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>
