<template>
  <div class="ipxx-logout-view mx-4 pt-5">
    <ipxx-logout-header />
    <b-row
      v-if="isMyPmBridgeError"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col mt-3">
        <b-alert
          show
          variant="warning"
          v-html="$t('errors.unknown_qt_function')"
        />
      </div>
    </b-row>
    <component :is="currentLogoutComponent" />
  </div>
</template>

<script>
import IpxxAzureLogout from '@/components/AzureLogout';
import IpxxDummyLogout from '@/components/DummyLogout';
import IpxxLogoutHeader from '@/components/LogoutHeader';

export default {
  name: 'LogoutView',
  components: {
    IpxxAzureLogout,
    IpxxDummyLogout,
    IpxxLogoutHeader,
  },
  computed: {
    currentLogoutComponent() {
      const { authProviderId } = this.$route.params;
      let componentName;
      switch (authProviderId) {
        case this.$config.providers.azure:
          componentName = 'IpxxAzureLogout';
          break;
        default:
          componentName = 'IpxxDummyLogout';
      }
      return componentName;
    },
    isMyPmBridgeError() {
      const error = this.$store.getters.getError;
      return error === this.$config.bridgeErrors.unknown_function;
    },
  },
};
</script>
