<!-- eslint-disable max-len -->
<i18n>
  {
   "de": {
      "email": "E-Mail-Adresse",
      "error_unable_to_sent": "Die E-Mail konnte nicht an Ihre E-Mail-Adresse »{email}« gesendet werden. Bitte versuchen Sie es mit einer anderen E-Mail-Adresse erneut.",
      "error_account_not_available": "Ihr neu registriertes {account} konnte nicht gefunden werden.<br>Bitte starten Sie die Anmeldung von vorne, indem Sie auf »{cancelButtonText}« klicken.",
      "error_auth_disabled": "Es konnte keine E-Mail verschickt werden, da ein Problem mit der Konfiguration aufgetreten ist.<br>Bitte wenden Sie sich an Ihren Administrator.",
      "input_feedback": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
      "intro": "Ihr Account benötigt noch eine verifizierte E-Mail-Adresse.<br>Bitte geben Sie hier Ihre E-Mail-Adresse ein und klicken Sie auf »{submitButtonText}«, um eine E-Mail mit einem Bestätigungslink zu erhalten.<br><br>Falls Sie die E-Mail nicht erhalten haben, prüfen Sie bitte auch Ihren Spam-Ordner.<br>Wenn Sie Hilfe benötigen, zögern Sie nicht, uns unter {support_email} zu kontaktieren!",
      "verify_now": "Jetzt senden",
      "verify_successful": "<b>Rufen Sie bitte Ihre E-Mails ab.</b><br><br>Wir haben eine Nachricht an »{email}« gesendet.<br>Falls Sie die E-Mail nicht erhalten haben, prüfen Sie bitte auch Ihren Spam-Ordner.<br>Nach der Bestätigung der E-Mail klicken Sie bitte auf »{submitButtonText}«, um mit der Anmeldung fortfahren zu können."
    },
    "en": {
      "email": "Email address",
      "error_unable_to_sent": "The email could not be sent to your email address ‘{email}’. Please try again with another email address.",
      "error_account_not_available": "Your newly registered {account} could not be found.<br>Please restart the login by clicking on ‘{cancelButtonText}‘.",
      "error_auth_disabled": "No email could be sent because of a problem with the configuration.<br>Please contact your administrator.",
      "input_feedback": "Please enter a valid email address.",
      "intro": "Your account still needs a verified email address.<br>Please enter your email address here and click ‘{submitButtonText}’ to receive an email with a confirmation link.<br><br>If you did not receive the email, please check your spam folder as well.<br>If you need any help, don’t hesitate to contact us at {support_email} !",
      "verify_now": "Send now",
      "verify_successful": "<b>Please check your emails.</b><br><br>We have sent a message to ‘{email}’.<br>If you have not received the email, please check your spam folder as well.<br>After confirming the email, please click ‘{submitButtonText}‘ to proceed with registration."
    }
  }
</i18n>
<!-- eslint-enable max-len -->

<template>
  <div class="ipxx-verify-email-form px-5">
    <b-row
      v-if="showSuccessMessage"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col mt-3">
        <b-alert
          show
          variant="success"
          data-cy="successMessage"
          v-html="$t('verify_successful', {
            email: mailAddress,
            submitButtonText: nextButtonText
          })"
        />
        <b-btn
          type="submit"
          variant="primary"
          class="float-right"
          data-cy="continueSubmitBtn"
          @click="continueLogin"
        >
          {{ nextButtonText }}
        </b-btn>
      </div>
    </b-row>
    <div v-else-if="!isLoading">
      <b-row
        v-if="!errorCode"
        class="justify-content-center"
      >
        <div class="ipxx-form-responsive-col">
          <p
            v-html=" $t('intro', {
              submitButtonText: sendButtonText,
              support_email: $t('corporate.support_email')
            })"
          />
        </div>
      </b-row>
      <b-row
        v-if="errorCode"
        class="justify-content-center"
      >
        <div class="ipxx-form-responsive-col mt-3">
          <b-alert
            :show="errorCode !== null"
            variant="danger"
            data-cy="errorMessage"
            v-html="errorMessage"
          />
        </div>
      </b-row>
      <b-form
        class="text-left my-4"
        @submit.prevent="verifyEmail(userInput)"
      >
        <b-form-row class="justify-content-center">
          <div class="ipxx-form-responsive-col">
            <b-form-group
              label-for="inputEmail"
              label-align="left"
              data-cy="emailFormGroup"
              :label="`${$t('email')}*`"
              :invalid-feedback="$t('input_feedback')"
            >
              <b-form-input
                id="inputEmail"
                v-model="userInput"
                autocomplete="email"
                :state="userInputState"
                trim
                @blur.native="$v.userInput.$touch()"
              />
            </b-form-group>
          </div>
        </b-form-row>
        <b-form-row class="justify-content-center">
          <div class="ipxx-form-responsive-col">
            <b-btn
              type="submit"
              variant="primary"
              class="float-right"
              data-cy="verifyEmailSubmitBtn"
              :disabled="$v.userInput.$invalid"
            >
              {{ sendButtonText }}
            </b-btn>
            <b-btn
              class="float-left"
              data-cy="cancelBtn"
              @click="restartAdLogin"
            >
              {{ cancelButtonText }}
            </b-btn>
          </div>
        </b-form-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import restartAdMixin from '@/components/mixins/restartAdMixin';

export default {
  name: 'VerifyEmailForm',
  mixins: [validationMixin, restartAdMixin],
  props: {
    userEmail: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      errorCode: null,
      showSuccessMessage: false,
      userInput: null,
      mailAddress: '',
    };
  },
  computed: {
    cancelButtonText() {
      return this.$t('labels.cancel');
    },
    errorMessage() {
      if (this.errorCode === this.$config.errors.account_email_verify_send_failed) {
        return this.$t('error_unable_to_sent', { email: this.mailAddress });
      }
      if (this.errorCode === this.$config.errors.account_email_verify_account_not_available) {
        return this.$t('error_account_not_available', {
          account: this.$t('corporate.mypm_account'),
          cancelButtonText: this.cancelButtonText,
        });
      }
      if (this.errorCode === this.$config.errors.account_email_verify_external_auth_disabled) {
        return this.$t('error_auth_disabled');
      }
      return this.$t('errors.unknown_error');
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    nextButtonText() {
      return this.$t('labels.next');
    },
    sendButtonText() {
      return this.$t('verify_now');
    },
    userInputState() {
      if (!this.$v.userInput.$dirty) {
        return null;
      }
      return !this.$v.userInput.$invalid;
    },
  },
  mounted() {
    if (this.userEmail) {
      this.verifyEmail(this.userEmail);
    }
  },
  methods: {
    /**
     * Calls route 'authorize' to continue with the login.
     */
    continueLogin() {
      this.$store.dispatch('authorizeUser');
    },
    /**
     * Sends an email to the given email address or to the email from the user input.
     * @param emailToVerify
     */
    verifyEmail(emailToVerify) {
      if (!email(emailToVerify)) {
        this.$logger.error('email to verify is not valid!');
      }
      this.$store.commit('setLoading', true);
      this.$http.post(this.$routes.email_send_verification_mail, { email: emailToVerify })
        .then(() => {
          this.showSuccessMessage = true;
        })
        .catch((error) => {
          this.userInput = '';
          if (error.data && error.data.failureId !== null) {
            this.errorCode = error.data.failureId;
          } else {
            this.errorCode = this.$config.errors.internal_server_error;
          }
        }).finally(() => {
          this.mailAddress = emailToVerify; // save email address to display in message
          this.$store.commit('setLoading', false);
        });
    },
  },
  validations: {
    userInput: {
      email,
      required,
    },
  },
};
</script>
